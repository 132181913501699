.button {
    --background: var(--button_background, var(--color-white));
    --hover-background: var(--button_hover-background, var(--color-black-lighten-4));
    --color: var(--button_color, var(--color-black));
    --background-opacity: var(--button_background-opacity, 0.1);
    --border-color: var(--button_border-color, var(--color-black));
    --outline-color: var(--button_outline-color, var(--color-black));
    --border-radius: var(--global-border-radius, 4px);
    min-width: 100px;
    padding: calc(0.5 * var(--gap)) var(--gap);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-color: hsla(var(--background), var(--background-opacity));
    font-size: var(--font-size-00);
    line-height: var(--line-height-md);
    text-align: center;
    border-radius: var(--border-radius);
    border: 1px solid hsla(var(--border-color), 0.5);

    &:not([disabled]) {

        &:focus,
        &:hover {
            background-color: hsl(var(--hover-background));

            &,
            a {
                color: hsl(var(--color-white));
                transition-duration: var(--transition-out);
            }
        }

        &:not(.isGrouped) {

            &:focus {
                box-shadow: 0 0 0 3px hsla(var(--outline-color), 0.25);
            }

            &:active {
                transition-duration: 0s;
                box-shadow: 0 0 0 3px hsla(var(--outline-color), 0.15), 0 0 0 6px hsla(var(--outline-color), 0.1), 0 0 0 9px hsla(var(--outline-color), 0.05);
            }
        }
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &::selection {
        background: transparent;
        color: currentcolor;
    }

    + .button:not(.isGrouped) {
        margin-left: calc(0.25 * var(--gap));
    }

    &.important {
        --color: var(--color-white);
        --background-opacity: 0.75;
    }

    &,
    a {
        color: hsl(var(--color));
        text-decoration: none;
        transition-property: background-color, color, box-shadow;
        transition-duration: var(--transition-in);
        transition-timing-function: var(--transition-timing);
    }

    svg {
        max-width: 22px;
        max-height: 22px;
        margin-right: calc(0.5 * var(--gap));
    }
}

.isGrouped {
    --background-opacity: 0.2;
    display: inline-flex;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:not([disabled]):focus {
        box-shadow: inset 0 0 0 2px hsla(var(--outline-color), 0.25);
    }

    &:disabled {
        border-color: transparent;
    }

    &:last-child {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }

    + .isGrouped {
        border-left: none;

        &:not(:last-child) {
            border-radius: 0;
        }
    }
}

.hasOnlyIcon {
    min-width: 0;
    padding: calc(0.5 * var(--gap));

    svg {
        margin: 0;
    }
}

.success {
    --variant: var(--global_success-color, var(--color-green));
}

.info {
    --variant: var(--global_info-color, var(--color-blue));
}

.warning {
    --variant: var(--global_warning-color, var(--color-yellow));
}

.danger {
    --variant: var(--global_danger-color, var(--color-red));
}

.success,
.info,
.warning,
.danger {
    --color: var(--variant);
    --background: var(--variant);
    --outline-color: var(--variant);
    --hover-background: var(--variant);
    --border-color: var(--variant);

    &.important {
        --color: var(--color-white);
        --background-opacity: 0.75;
        --border-color: var(--variant);
    }

    &.isGrouped {
        border-color: transparent;
    }
}
