.main {
    min-height: 100vh;
    display: grid;
    grid-template: [header-start] 'header' max-content [header-end]
        [hero-start] 'hero' auto [header-end]
        [section-start] 'section' auto [section-end]
        [form-start] 'form' auto [form-end]
        [footer-start] 'footer' max-content [footer-end]
        / 100vw;
    background-color: hsl(var(--color-white-darken-3));

    noscript {
        margin: 0 auto;
        text-align: center;
    }
}

.alert {
    top: 12vh !important;
    font-size: var(--font-size-1);
    border-radius: 8px !important;
}
