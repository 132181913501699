.locales {
    margin-left: auto;
    display: flex;

    @media (min-width: 1200px) {
        margin-left: var(--gap);
    }

    @media (min-width: 1500px) {
        margin-left: calc(2 * var(--gap));
    }
}

.item {
    margin: 0 calc(0.5 * var(--gap));
    display: inline-flex;
    align-content: center;
    font-weight: 600;
}

.anchor {
    color: hsl(var(--color-black));
    line-height: 0;
    text-decoration: none;
}

.disabled {
    opacity: 0.25;
    pointer-events: none;
    outline: 2px solid hsl(var(--color-gold));
    outline-offset: 4px;
    border-radius: var(--global-border-radius);
}
