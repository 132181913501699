.header {
    height: var(--global-header-height);
    padding: var(--gap) calc(2 * var(--gap));
    display: grid;
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: var(--zindex-header);
    background-color: hsl(var(--color-white));
    box-shadow: 0 10px 20px hsla(var(--color-black), 0.25);

    @media (min-width: 1200px) {
        overflow: hidden;
    }
}

.container {
    width: var(--global-container-width);
    max-width: var(--global-container-max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
