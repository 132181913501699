.content {
    width: var(--global-container-width);
    max-width: var(--global-container-max-width);
    min-height: 75vh;
    margin: 0 auto;
    padding: calc(2 * var(--gap)) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.animation {
    width: auto;
    max-width: 600px;
    max-height: 100%;
    margin: auto;
}
