.nav {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;

    @media (min-width: 1200px) {
        width: auto;
    }
}

.list {

    @media (max-width: 1199px) {
        display: none;
    }
}

.item {
    display: inline-flex;
}

.button {
    position: relative;
    transition: color var(--transition-out) var(--transition-timing);

    &:hover,
    &:focus {

        &::after {
            transform: translateY(0);
            transition-duration: var(--transition-out);
        }
    }

    &,
    &.active {

        &::after {
            width: 100%;
            height: 8px;
            position: absolute;
            bottom: calc(-1 * ((var(--global-header-height) - (calc(var(--font-size, 16px) * 1.5))) / 2));
            left: 0;
            content: '';
            background-color: hsl(var(--color-gold));
            transform: translateY(8px);
            transition-property: transform;
            transition-duration: var(--transition-in);
            transition-timing-function: var(--transition-timing);
            border-top-right-radius: var(--global-border-radius);
            border-top-left-radius: var(--global-border-radius);
        }
    }

    &.active {
        color: hsl(var(--color-gold));
        transition-duration: var(--transition-in);
    }

    @media (max-width: 1199px) {

        &::after {
            display: none;
        }
    }

    @media (min-width: 1200px) {
        margin: 0 calc(0.5 * var(--gap));
    }

    @media (min-width: 1500px) {
        margin: 0 var(--gap);
    }
}
